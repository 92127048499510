import React, { useState, useEffect } from 'react';
import Logo from '../../Assets/images/logo-rectangle.png';
import axios from 'axios';
import { apiurl } from '../Config/Config';
import { Link, useNavigate } from "react-router-dom";
import { FaExclamationTriangle, FaEye, FaEyeSlash, FaCheck } from "react-icons/fa";
import { AiOutlineLoading } from "react-icons/ai";

const Register = () => {

  const [name, setname] = useState('');
  const [email, setEmail] = useState('');
  const [mobile, setMobile] = useState('');
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [hidePassword, setHidePassword] = useState(true);
  const [inputType1, setInputType1] = useState('password');
  const [errorMsg, setErrorMsg] = useState('');
  const [successMsg, setSuccessMsg] = useState('');
  const [isNameError, setNameError] = useState(false);
  const [isEmailError, setEmailError] = useState(false);
  const [isMobileError, setMobileError] = useState(false);
  const [isPwdError, setPwdError] = useState(false);
  const [displayLoader, setDisplayLoader] = useState('none');

  const navigate = useNavigate();

  useEffect(() => {
    const userid = localStorage.getItem('userid');
    const loggedIn = localStorage.getItem('loggedIn');

    if (userid !== "" && loggedIn === true) {
      navigate('/dashboard');
    }
  }, [navigate])

  const handleFormSubmit = (e) => {
    e.preventDefault();
    setErrorFree();

    if (name === "") {
      setNameError(true);
      setErrorMsg('Name is required');
      return false;
    }

    if (email === "") {
      setEmailError(true);
      setErrorMsg('Email is required');
      return false;
    } else if (email !== "") {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (!emailRegex.test(email)) {
        setEmailError(true);
        setErrorMsg('Enter a valid email address');
        return false;
      }
    }

    if (mobile === "") {
      setMobileError(true);
      setErrorMsg('Mobile is required');
      return false;
    } else if (mobile !== "") {
      const mobileRegex = /^[0-9]{10}$/;
      if (!mobileRegex.test(mobile)) {
        setMobileError(true);
        setErrorMsg('Enter a valid 10-digit mobile number');
        return false;
      }
    }

    if (password === "") {
      setPwdError(true);
      setErrorMsg('Password is required');
      return false;
    } else if (password !== "") {
      // Regex for validating password: 8 characters, 1 uppercase, 1 lowercase, 1 number, 1 special character
      const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
      if (!passwordRegex.test(password)) {
        setErrorMsg('Uh-oh! Your password needs at least 8 characters, one uppercase, one lowercase, one number, and one special character');
        return false;
      }
    }

    const postObject = {
      name: name,
      email: email,
      mobile: mobile,
      password: password
    };

    postData(postObject);
  }

  const setErrorFree = () => {
    setErrorMsg('');
    setSuccessMsg('');

    setNameError(false);
    setEmailError(false);
    setMobileError(false);
    setPwdError(false);

    setDisplayLoader('none');
  }

  const clearFields = () => {
    setname('');
    setEmail('');
    setMobile('');
    setPassword('');

    setTimeout(() => {
      setErrorFree();
    }, 5000);
  }

  const postData = async (postObject) => {
    var qs = require('qs');
    try {
      setDisplayLoader('inline-block');
      const response = await axios.post(apiurl + 'user/register', qs.stringify(postObject));
      if (response && response.data.status === "success") {
        setErrorFree();
        setSuccessMsg(response.data.message);
        // setSuccessDisplay('block');
        clearFields();
      } else {
        setErrorFree();
        setErrorMsg(response.data.message);
      }
    } catch (err) {
      setErrorMsg(err);
    }
  };

  const showPwd = (num) => {
    setShowPassword(true);
    setHidePassword(false);
    setInputType1('text');
  }

  const hidePwd = (num) => {
    setShowPassword(false);
    setHidePassword(true);
    setInputType1('password');
  }

  return (
    <div className="container-scroller">
      <div className="container-fluid page-body-wrapper full-page-wrapper">
        <div className="content-wrapper d-flex align-items-center auth">
          <div className="row flex-grow">
            <div className="col-lg-4 mx-auto">
              <div className="auth-form-light text-left p-5">
                <div className="brand-logo">
                  <img src={Logo} />
                </div>
                <h4>New here?</h4>
                <h6 className="font-weight-light">Signing up is easy. It only takes a few steps</h6>
                <div className='err-display' style={{ display: (errorMsg) ? 'block' : 'none' }}> <FaExclamationTriangle /> <p>{errorMsg}</p></div>
                <div className='success-display' style={{ display: (successMsg) ? 'block' : 'none' }}> <FaCheck /> <p>{successMsg}</p></div>
                <form className="pt-3" onSubmit={handleFormSubmit} autoComplete="off">
                  <div className="form-group">
                    <input
                      type="text"
                      className={isNameError ? "form-control form-control-lg err-field" : "form-control form-control-lg"}
                      id="exampleInputname1"
                      placeholder="Name"
                      onChange={(e) => setname(e.target.value)}
                      value={name}
                    />
                  </div>
                  <div className="form-group">
                    <input
                      type="email"
                      className={isEmailError ? "form-control form-control-lg err-field" : "form-control form-control-lg"}
                      id="exampleInputEmail1"
                      placeholder="Email"
                      onChange={(e) => setEmail(e.target.value)}
                      value={email}
                    />
                  </div>
                  <div className="form-group">
                    <input
                      type="number"
                      className={isMobileError ? "form-control form-control-lg err-field" : "form-control form-control-lg"}
                      id="exampleInputMobile1"
                      placeholder="Mobile"
                      onChange={(e) => setMobile(e.target.value)}
                      value={mobile}
                    />
                  </div>
                  <div className="form-group pwd-div">
                    <input
                      type={inputType1}
                      className={isPwdError ? "form-control form-control-lg err-field" : "form-control form-control-lg"}
                      id="exampleInputPassword1"
                      placeholder="Password"
                      onChange={(e) => setPassword(e.target.value)}
                      value={password}
                    />
                    {showPassword ? <FaEyeSlash className='pwd-show' onClick={(e) => hidePwd(2)} /> : <FaEye className='pwd-show' onClick={(e) => showPwd(2)} />}
                    <p className='note-text' style={{ paddingTop: "10px" }}>Note : Remember, the password you enter should be used when you log in</p>
                  </div>
                  {/* <div className="form-group pwd-div">
                    <input
                      type={inputType2}
                      className="form-control form-control-lg"
                      id="exampleInputPassword2"
                      placeholder="Re-Enter Password"
                      onChange={(e) => setConfPassword(e.target.value)}                      
                    />
                    {showPassword ? <FaEyeSlash className='pwd-show' onClick={(e) => hidePwd(2)} /> : <FaEye className='pwd-show' onClick={(e) => showPwd(2)} />}
                  </div> */}
                  {/* <div className="mb-4">
                    <div className="form-check">
                      <label className="form-check-label text-muted">
                        <input type="checkbox" className="form-check-input" onChange={(e) => setAgreeTerms(e.target.value)} /> I agree to all Terms & Conditions <i class="input-helper"></i></label>
                    </div>
                  </div> */}
                  <div className="mt-3">
                    <button className="btn btn-block btn-gradient-primary btn-lg font-weight-medium auth-form-btn" type="submit" disabled={(displayLoader == 'inline-block' ? 'disabled' : '')} >SIGN UP</button>
                    <AiOutlineLoading className="loader" display={displayLoader} />
                  </div>
                  <div className="text-center mt-4 font-weight-light btm-link-txt"> Already have an account? <Link to="/login" className="text-primary">Login</Link>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Register