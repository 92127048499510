import axios from "axios";
import { apiurl } from "../Config/Config";

const checkSession = () => {
    const sessionStartTime = parseInt(localStorage.getItem('sessionStartTime'), 10);
    const sessionDuration = parseInt(localStorage.getItem('sessionDuration'), 10);
    const currentTime = new Date().getTime();
    let isSessionValid = false;

    if (sessionStartTime && sessionDuration) {
        const timeElapsed = currentTime - sessionStartTime;

        if (timeElapsed > sessionDuration) {
            localStorage.clear();
        } else {
            isSessionValid = true;
        }
    } else {
        localStorage.clear();
    }

    return isSessionValid;
}

const appliedStandards = async () => {
    let records, appStdList = [];
    const response = await axios.post(apiurl + 'appliedstandards/list_select');
    if (response && response.data.status === "success") {
        records = response.data.data;
        for (let i = 0; i < records.length; i++) {
            appStdList[i] = { value: records[i]['value'], label: records[i]['label'] };
        }
    }

    return appStdList;
}

const getCurrentDate = () => {
    const today = new Date();
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, '0'); // Months are zero-indexed, so we add 1
    const day = String(today.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
};

export const Commonhelper = {
    title: "ESICO",
    // applied_standards: appliedStandards(),
    getCurrentDate: getCurrentDate(),
    applied_standards: [
        {
            "value": "4",
            "label": "AIS-004"
        },
        {
            "value": "3",
            "label": "AIS-003"
        },
        {
            "value": "2",
            "label": "AIS-002"
        },
        {
            "value": "1",
            "label": "AIS-001"
        }
    ],
    gender: [
        {
            "value": "male",
            "label": "Male"
        },
        {
            "value": "female",
            "label": "Female"
        }
    ],
    checkSession: checkSession(),
    certificate_title: [
        { label: "CERTIFICATE OF THOROUGH EXAMINATION AND / OR TEST OF LIFTING APPLIANCE OR ACCESSORY", value: "CERTIFICATE OF THOROUGH EXAMINATION AND / OR TEST OF LIFTING APPLIANCE OR ACCESSORY" },
        { label: "CERTIFICATE OF THOROUGH EXAMINATION AND / OR TEST OF EARTH MOVING EQUIPMENTS", value: "CERTIFICATE OF THOROUGH EXAMINATION AND / OR TEST OF EARTH MOVING EQUIPMENTS" },
        { label: "CERTIFICATE OF THOROUGH EXAMINATION OF ELECTRIC POWER EQUIPMENTS", value: "CERTIFICATE OF THOROUGH EXAMINATION OF ELECTRIC POWER EQUIPMENTS" }
    ],
    recordsPerPage: 10
}