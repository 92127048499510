import React, { useState, useEffect } from 'react';
import Logo from '../../Assets/images/logo-rectangle.png';
import { Link } from "react-router-dom";
import { FaExclamationTriangle, FaCheck } from "react-icons/fa";
import { AiOutlineLoading } from "react-icons/ai";
import axios from 'axios';
import { apiurl } from '../Config/Config';

const Forgotpassword = () => {

    const [email, setEmail] = useState('');
    const [errorMsg, setErrorMsg] = useState('');
    const [successMsg, setSuccessMsg] = useState('');
    const [isEmailError, setEmailError] = useState(false);
    const [displayLoader, setDisplayLoader] = useState('none');

    const handleFormSubmit = (e) => {
        e.preventDefault();
        setErrorFree();

        if (email === "") {
            setEmailError(true);
            setErrorMsg('Email is required');
            return false;
        } else if (email !== "") {
            const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
            if (!emailRegex.test(email)) {
                setEmailError(true);
                setErrorMsg('Enter a valid email address');
                return false;
            }
        }

        const postObject = {
            email: email
        };

        postData(postObject);
    }

    const setErrorFree = () => {
        setErrorMsg('');
        setSuccessMsg('');        
        setEmailError(false);
        setDisplayLoader('none');
    }

    const postData = async (postObject) => {
        var qs = require('qs');
        try {
          setDisplayLoader('inline-block');
          const response = await axios.post(apiurl + 'user/forgotpassword', qs.stringify(postObject));
          if (response && response.data.status === "success") {
            setErrorFree();
            setSuccessMsg(response.data.message);
          } else {
            setErrorFree();
            setErrorMsg(response.data.message);
          }
        } catch (err) {
          setErrorMsg(err);
        }
      };

    return (
        <div className="container-scroller">
            <div className="container-fluid page-body-wrapper full-page-wrapper">
                <div className="content-wrapper d-flex align-items-center auth">
                    <div className="row flex-grow">
                        <div className="col-lg-4 mx-auto">
                            <div className="auth-form-light text-left p-5">
                                <div className="brand-logo">
                                    <img src={Logo} />
                                </div>
                                <h4>Don't Worry! let's reset the password</h4>
                                <h6 className="font-weight-light">Enter email to continue.</h6>
                                <div className='err-display' style={{ display: (errorMsg) ? 'block' : 'none' }}> <FaExclamationTriangle /> <p>{errorMsg}</p></div>
                                <div className='success-display' style={{ display: (successMsg) ? 'block' : 'none' }}> <FaCheck /> <p>{successMsg}</p></div>
                                <form className="pt-3" onSubmit={handleFormSubmit} autoComplete="off">
                                    <div className="form-group">
                                        <input
                                            type="email"
                                            className={isEmailError ? "form-control form-control-lg err-field" : "form-control form-control-lg"}
                                            id="exampleInputEmail1"
                                            placeholder="Email"
                                            onChange={(e) => setEmail(e.target.value)}
                                            value={email}
                                        />
                                    </div>
                                    <div className="mt-3">
                                        <button className="btn btn-block btn-gradient-primary btn-lg font-weight-medium auth-form-btn" type="submit" disabled={(displayLoader == 'inline-block' ? 'disabled' : '')} >RESET</button>
                                        <AiOutlineLoading className='loader' display={displayLoader} />
                                    </div>

                                    <div className="text-center mt-4 font-weight-light btm-link-txt"> Already have an account?
                                        <Link to="/login" className="text-primary">Login</Link>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Forgotpassword