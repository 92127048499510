import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from "react-router-dom";
import Sidemenu from './Layout/Sidemenu';
import Topbar from './Layout/Topbar';
import Footer from './Layout/Footer';
import circleImage from '../Assets/images/circle.svg';
import { FaHome } from "react-icons/fa";
import { Commonhelper } from './Helpers/Commonhelper';
import { apiurl } from './Config/Config';
import axios from 'axios';

const Dashboard = () => {

    const navigate = useNavigate();
    const [isSessionValid, setIsSessionValid] = useState(true);
    const [totalCertificatesCount, setTotalCertificatesCount] = useState(0);
    const [thisWeekCount, setThisWeekCount] = useState(0);
    const [thisMonthCount, setThisMonthCount] = useState(0);
    const [thisMonthName, setThisMonthName] = useState("");
    const [thisWeekRange, setThisWeekRange] = useState("");

    useEffect(() => {
        const sessionStartTime = parseInt(localStorage.getItem('sessionStartTime'), 10);
        const sessionDuration = parseInt(localStorage.getItem('sessionDuration'), 10);
        const currentTime = new Date().getTime();

        if (sessionStartTime && sessionDuration) {
            const timeElapsed = currentTime - sessionStartTime;

            if (timeElapsed > sessionDuration) {
                // Session expired
                localStorage.clear();
                setIsSessionValid(false);
                navigate('/login');
            } else {
                // Session still valid
                setIsSessionValid(true);

                const postObject = {
                    userid: localStorage.getItem('unique_id')
                };

                fetchData(postObject);

                const date = new Date();
                const month = date.toLocaleString('default', { month: 'long' });
                setThisMonthName(month);
            }
        } else {
            // No session data found
            localStorage.clear();
            setIsSessionValid(false);
            navigate('/login');
        }
    }, [navigate]);

    const fetchData = async (postObject) => {
        try {
            var qs = require('qs');
            const response = await axios.post(apiurl + 'dashboard/dashboard_count', qs.stringify(postObject));
            if (response && response.data.status === "success") {
                setTotalCertificatesCount(response.data.counts.total_certificates);
                setThisWeekCount(response.data.counts.this_week);
                setThisMonthCount(response.data.counts.this_month);
                setThisWeekRange(response.data.counts.week_range);
            }
        } catch (err) {
            //   setErrorMsg(err);
        }
    };

    /*useEffect(() => {
        if (Commonhelper.checkSession === false) {
            navigate("/login");
        }
    }, [navigate]);*/

    return (
        <div className="container-scroller">
            <Topbar />
            <div className="container-fluid page-body-wrapper panel-area">
                <Sidemenu />
                {/* Dashboard */}
                <div className="main-panel">
                    <div className="content-wrapper">
                        <div className="page-header">
                            <h3 className="page-title">
                                <span className="page-title-icon bg-gradient-primary text-white me-2">
                                    <FaHome />
                                </span> Dashboard
                            </h3>
                            {/* <nav aria-label="breadcrumb">
                                <ul className="breadcrumb">
                                    <li className="breadcrumb-item active" aria-current="page">
                                        <span></span>Dashboard Overview
                                    </li>
                                </ul>
                            </nav> */}
                        </div>
                        <div className="row">
                            <div className="col-md-4 stretch-card grid-margin">
                                <div className="card bg-gradient-info card-img-holder text-white">
                                    <div className="card-body">
                                        <img src={circleImage} className="card-img-absolute" alt="circle-image" />
                                        <h4 className="font-weight-normal mb-3">Total Certificates <i className="mdi mdi-bookmark-outline mdi-24px float-right"></i>
                                        </h4>
                                        <h2 className="mb-5">{totalCertificatesCount}</h2>
                                        {/* <h6 className="card-text">Decreased by 10%</h6> */}
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-4 stretch-card grid-margin">
                                <div className="card bg-gradient-danger card-img-holder text-white">
                                    <div className="card-body">
                                        <img src={circleImage} className="card-img-absolute" alt="circle-image" />
                                        <h4 className="font-weight-normal mb-3">This Week <i className="mdi mdi-chart-line mdi-24px float-right"></i>
                                        </h4>
                                        <h2 className="mb-5">{thisWeekCount}</h2>
                                        <h6 className="card-text">{thisWeekRange}</h6>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-4 stretch-card grid-margin">
                                <div className="card bg-gradient-success card-img-holder text-white">
                                    <div className="card-body">
                                        <img src={circleImage} className="card-img-absolute" alt="circle-image" />
                                        <h4 className="font-weight-normal mb-3">This Month <i className="mdi mdi-diamond mdi-24px float-right"></i>
                                        </h4>
                                        <h2 className="mb-5">{thisMonthCount}</h2>
                                        <h6 className="card-text">{thisMonthName}</h6>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* <div className="row">
                            <div className="col-md-7 grid-margin stretch-card">
                                <div className="card">
                                    <div className="card-body">
                                        <div className="clearfix">
                                            <h4 className="card-title float-left">Visit And Sales Statistics</h4>
                                            <div id="visit-sale-chart-legend" className="rounded-legend legend-horizontal legend-top-right float-right"></div>
                                        </div>
                                        <canvas id="visit-sale-chart" className="mt-4"></canvas>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-5 grid-margin stretch-card">
                                <div className="card">
                                    <div className="card-body">
                                        <h4 className="card-title">Traffic Sources</h4>
                                        <canvas id="traffic-chart"></canvas>
                                        <div id="traffic-chart-legend" className="rounded-legend legend-vertical legend-bottom-left pt-4"></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12 grid-margin">
                                <div className="card">
                                    <div className="card-body">
                                        <h4 className="card-title">Recent Tickets</h4>
                                        <div className="table-responsive">
                                            <table className="table">
                                                <thead>
                                                    <tr>
                                                        <th> Assignee </th>
                                                        <th> Subject </th>
                                                        <th> Status </th>
                                                        <th> Last Update </th>
                                                        <th> Tracking ID </th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <td>
                                                            <img src="assets/images/faces/face1.jpg" className="me-2" alt="image" /> David Grey
                                                        </td>
                                                        <td> Fund is not recieved </td>
                                                        <td>
                                                            <label className="badge badge-gradient-success">DONE</label>
                                                        </td>
                                                        <td> Dec 5, 2017 </td>
                                                        <td> WD-12345 </td>
                                                    </tr>
                                                    <tr>
                                                        <td>
                                                            <img src="assets/images/faces/face2.jpg" className="me-2" alt="image" /> Stella Johnson
                                                        </td>
                                                        <td> High loading time </td>
                                                        <td>
                                                            <label className="badge badge-gradient-warning">PROGRESS</label>
                                                        </td>
                                                        <td> Dec 12, 2017 </td>
                                                        <td> WD-12346 </td>
                                                    </tr>
                                                    <tr>
                                                        <td>
                                                            <img src="assets/images/faces/face3.jpg" className="me-2" alt="image" /> Marina Michel
                                                        </td>
                                                        <td> Website down for one week </td>
                                                        <td>
                                                            <label className="badge badge-gradient-info">ON HOLD</label>
                                                        </td>
                                                        <td> Dec 16, 2017 </td>
                                                        <td> WD-12347 </td>
                                                    </tr>
                                                    <tr>
                                                        <td>
                                                            <img src="assets/images/faces/face4.jpg" className="me-2" alt="image" /> John Doe
                                                        </td>
                                                        <td> Loosing control on server </td>
                                                        <td>
                                                            <label className="badge badge-gradient-danger">REJECTED</label>
                                                        </td>
                                                        <td> Dec 3, 2017 </td>
                                                        <td> WD-12348 </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12 grid-margin stretch-card">
                                <div className="card">
                                    <div className="card-body">
                                        <h4 className="card-title">Recent Updates</h4>
                                        <div className="d-flex">
                                            <div className="d-flex align-items-center me-4 text-muted font-weight-light">
                                                <i className="mdi mdi-account-outline icon-sm me-2"></i>
                                                <span>jack Menqu</span>
                                            </div>
                                            <div className="d-flex align-items-center text-muted font-weight-light">
                                                <i className="mdi mdi-clock icon-sm me-2"></i>
                                                <span>October 3rd, 2018</span>
                                            </div>
                                        </div>
                                        <div className="row mt-3">
                                            <div className="col-6 pe-1">
                                                <img src="assets/images/dashboard/img_1.jpg" className="mb-2 mw-100 w-100 rounded" alt="image" />
                                                <img src="assets/images/dashboard/img_4.jpg" className="mw-100 w-100 rounded" alt="image" />
                                            </div>
                                            <div className="col-6 ps-1">
                                                <img src="assets/images/dashboard/img_2.jpg" className="mb-2 mw-100 w-100 rounded" alt="image" />
                                                <img src="assets/images/dashboard/img_3.jpg" className="mw-100 w-100 rounded" alt="image" />
                                            </div>
                                        </div>
                                        <div className="d-flex mt-5 align-items-top">
                                            <img src="assets/images/faces/face3.jpg" className="img-sm rounded-circle me-3" alt="image" />
                                            <div className="mb-0 flex-grow">
                                                <h5 className="me-2 mb-2">School Website - Authentication Module.</h5>
                                                <p className="mb-0 font-weight-light">It is a long established fact that a reader will be distracted by the readable content of a page.</p>
                                            </div>
                                            <div className="ms-auto">
                                                <i className="mdi mdi-heart-outline text-muted"></i>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>                         */}
                    </div>
                    <Footer />
                </div>
            </div>
        </div>
    )
}

export default Dashboard