import React, { useState, useEffect } from 'react';
import Logo from '../../Assets/images/logo-rectangle.png';
import axios from 'axios';
import { apiurl } from '../Config/Config';
import { Link, useNavigate } from "react-router-dom";
import { FaExclamationTriangle, FaEye, FaEyeSlash, FaCheck } from "react-icons/fa";
import { AiOutlineLoading } from "react-icons/ai";

const Login = () => {

    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [showPassword, setShowPassword] = useState(false);
    const [hidePassword, setHidePassword] = useState(true);
    const [inputType1, setInputType1] = useState('password');
    const [errorMsg, setErrorMsg] = useState('');
    const [isEmailError, setEmailError] = useState(false);
    const [isPwdError, setPwdError] = useState(false);
    const [successMsg, setSuccessMsg] = useState('');
    const [displayLoader, setDisplayLoader] = useState('none');

    const navigate = useNavigate();

    useEffect(() => {
        const userid = localStorage.getItem('userid');
        const loggedIn = localStorage.getItem('loggedIn');

        if (userid !== "" && loggedIn === "true") {
            navigate('/dashboard');
        }
    }, [navigate])

    const handleFormSubmit = (e) => {
        e.preventDefault();
        setErrorFree();

        // if (mobile === "") {
        //     setMobileError(true);
        //     setErrorMsg('Mobile is required');
        //     return false;
        // } else if (mobile !== "") {
        //     const mobileRegex = /^[0-9]{10}$/;
        //     if (!mobileRegex.test(mobile)) {
        //         setMobileError(true);
        //         setErrorMsg('Enter a valid 10-digit mobile number');
        //         return false;
        //     }
        // }

        if (email === "") {
            setEmailError(true);
            setErrorMsg('Email is required');
            return false;
          } else if (email !== "") {
            const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
            if (!emailRegex.test(email)) {
              setEmailError(true);
              setErrorMsg('Enter a valid email address');
              return false;
            }
          }

        if (password === "") {
            setPwdError(true);
            setErrorMsg('Password is required');
            return false;
        }

        const postObject = {
            email: email,
            password: password
        };

        fetchData(postObject);
    }

    const fetchData = async (postObject) => {
        var qs = require('qs');
        try {
            setDisplayLoader('inline-block');
            const response = await axios.post(apiurl + 'user/login', qs.stringify(postObject));
            if (response && response.data.status === "success") {
                setErrorFree();
                setSuccessMsg(response.data.message);

                setTimeout(() => {
                    handleLogin(response.data.userdata);
                }, 1500)

            } else {
                setErrorFree();
                setErrorMsg(response.data.message);
            }
        } catch (err) {
            setErrorMsg(err);
        }
    };

    const setErrorFree = () => {
        setEmailError(false);
        setPwdError(false);

        setErrorMsg('');
        setSuccessMsg('');

        setDisplayLoader('none');
    }

    const showPwd = (num) => {
        setShowPassword(true);
        setHidePassword(false);
        setInputType1('text');
    }

    const hidePwd = (num) => {
        setShowPassword(false);
        setHidePassword(true);
        setInputType1('password');
    }

    const handleLogin = (userdata) => {
        if (userdata !== "") {
            const sessionStartTime = new Date().getTime();
            const sessionDuration = 720 * 60 * 1000;
            
            localStorage.clear();
            localStorage.setItem('userid', userdata.id);
            localStorage.setItem('unique_id', userdata.unique_id);
            localStorage.setItem('username', userdata.name);
            localStorage.setItem("user_type", userdata.user_type);
            localStorage.setItem("signature", userdata.signature);
            localStorage.setItem('sessionStartTime', sessionStartTime);
            localStorage.setItem('sessionDuration', sessionDuration);
            localStorage.setItem('loggedIn', 'true');

            window.location.href = "/dashboard"; // Redirect to dashboard
        } else {
            setErrorMsg('Invalid login credentials');
        }
    };

    return (
        <div className="container-scroller">
            <div className="container-fluid page-body-wrapper full-page-wrapper">
                <div className="content-wrapper d-flex align-items-center auth">
                    <div className="row flex-grow">
                        <div className="col-lg-4 mx-auto">
                            <div className="auth-form-light text-left p-5">
                                <div className="brand-logo">
                                    <img src={Logo} />
                                </div>
                                <h4>Hello! let's get started</h4>
                                <h6 className="font-weight-light">Sign in to continue.</h6>
                                <div className='err-display' style={{ display: (errorMsg) ? 'block' : 'none' }}> <FaExclamationTriangle /> <p>{errorMsg}</p></div>
                                <div className='success-display' style={{ display: (successMsg) ? 'block' : 'none' }}> <FaCheck /> <p>{successMsg}</p></div>
                                <form className="pt-3" onSubmit={handleFormSubmit} autoComplete="off">
                                    <div className="form-group">
                                        <input
                                            type="email"
                                            className={isEmailError ? "form-control form-control-lg err-field" : "form-control form-control-lg"} id="exampleInputEmail1"
                                            placeholder="Email"
                                            onChange={(e) => setEmail(e.target.value)}
                                        />
                                    </div>
                                    <div className="form-group pwd-div">
                                        <input
                                            type={inputType1}
                                            className={isPwdError ? "form-control form-control-lg err-field" : "form-control form-control-lg"} id="exampleInputPassword1"
                                            placeholder="Password"
                                            onChange={(e) => setPassword(e.target.value)}
                                        />
                                        {showPassword ? <FaEyeSlash className='pwd-show' onClick={(e) => hidePwd(2)} /> : <FaEye className='pwd-show' onClick={(e) => showPwd(2)} />}
                                    </div>
                                    <div className="mt-3">
                                        <button className="btn btn-block btn-gradient-primary btn-lg font-weight-medium auth-form-btn" type="submit" disabled={(displayLoader == 'inline-block' ? 'disabled' : '')} >SIGN IN</button>
                                        <AiOutlineLoading className="loader" display={displayLoader} />
                                    </div>
                                    <div className="my-2 d-flex justify-content-between align-items-center">
                                        <Link to="/forgotpassword" className="auth-link text-black">Forgot password?</Link>
                                    </div>
                                    <div className="text-center mt-4 font-weight-light btm-link-txt"> Don't have an account?
                                        <Link to="/register" className="text-primary">Create</Link>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Login