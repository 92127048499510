import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from "react-router-dom";
import { FaHome, FaUsers, FaUser } from "react-icons/fa";
import { RiSettings3Fill } from "react-icons/ri";
import { AiOutlineLogout } from "react-icons/ai";
import { GrCertificate } from "react-icons/gr";
import { apiurl } from '../Config/Config';
import axios from 'axios';

const Sidemenu = () => {

    const navigate = useNavigate();

    const currentUrl = window.location.pathname;

    const handleLogout = () => {
        localStorage.clear();
        navigate('/login');
    }

    return (
        <div>            
            <nav className="sidebar sidebar-offcanvas" id="sidebar">
                <ul className="nav">
                    <li className={(currentUrl === "/dashboard" ? "nav-item active" : "nav-item")}>
                        <Link to={"/dashboard"} className="nav-link">
                            <span className="menu-title">Dashboard</span>
                            <FaHome className='menu-icon' />
                        </Link>
                    </li>
                    {localStorage.getItem("user_type") === "admin" &&
                    <li className={(currentUrl.includes("users") ? "nav-item active" : "nav-item")}>
                        <Link to={"/users"} className="nav-link">
                            <span className="menu-title">Users</span>
                            <FaUsers className='menu-icon' />
                        </Link>
                    </li>
                    }
                    <li className={(currentUrl.includes("certificates") ? "nav-item active" : "nav-item")}>
                        <Link to={"/certificates"} className="nav-link">
                            <span className="menu-title">Certificates</span>
                            <GrCertificate className='menu-icon' />
                        </Link>
                    </li>
                    <li className={(currentUrl === "/settings" ? "nav-item active" : "nav-item")}>
                        <Link to={"/settings"} className="nav-link">
                            <span className="menu-title">Settings</span>
                            <RiSettings3Fill className='menu-icon' />
                        </Link>
                    </li>
                    {/* <li className="nav-item">
                            <a className="nav-link collapsed" data-bs-toggle="collapse" href="#general-pages" aria-expanded="false" aria-controls="general-pages">
                                <span className="menu-title">Sample Pages</span>
                                <IoIosArrowDown className="menu-arrow"/>
                            </a>
                            <div className="collapse show" id="general-pages">
                                <ul className="nav flex-column sub-menu">
                                    <li className="nav-item"> <a className="nav-link" href="pages/samples/blank-page.html"> Blank Page </a></li>
                                    <li className="nav-item"> <a className="nav-link" href="pages/samples/login.html"> Login </a></li>
                                    <li className="nav-item"> <a className="nav-link" href="pages/samples/register.html"> Register </a></li>
                                    <li className="nav-item"> <a className="nav-link" href="pages/samples/error-404.html"> 404 </a></li>
                                    <li className="nav-item"> <a className="nav-link" href="pages/samples/error-500.html"> 500 </a></li>
                                </ul>
                            </div>
                        </li> */}
                    <li className="nav-item">
                        <a className="nav-link" onClick={() => handleLogout()}>
                            <span className="menu-title">Logout</span>
                            <AiOutlineLogout className='menu-icon' />
                        </a>
                    </li>
                </ul>
                {/* <div className="username-display">
                    <p><FaUser/> {localStorage.getItem("username") ? localStorage.getItem("username") : ""}</p>
                </div> */}
            </nav>
        </div>
    )
}

export default Sidemenu