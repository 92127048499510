import React, { useState, useEffect, useRef } from 'react';
import { Link, useNavigate } from "react-router-dom";
import Sidemenu from '../Layout/Sidemenu';
import Topbar from '../Layout/Topbar';
import Footer from '../Layout/Footer';
import { apiurl, mediaurl } from '../Config/Config';
import axios from 'axios';
import { Commonhelper } from '../Helpers/Commonhelper';
import Select from 'react-select';
import { FaExclamationTriangle, FaEye, FaEyeSlash, FaCheck } from "react-icons/fa";
import { AiOutlineLoading } from "react-icons/ai";
import { RiSettings3Fill } from "react-icons/ri";
import SignaturePad from 'react-signature-canvas';
import dpplaceholder from '../../Assets/images/dp-placeholder.jpg';

const Settings = () => {

    const navigate = useNavigate();

    useEffect(() => {
        if (Commonhelper.checkSession === false) {
            navigate("/login");
        } else {
            const postObject = {
                userid: localStorage.getItem('userid')
            };

            fetchData(postObject);
        }
    }, [navigate]);

    const [displayLoader, setDisplayLoader] = useState('none');
    const [errorMsg, setErrorMsg] = useState('');
    const [successMsg, setSuccessMsg] = useState('');

    const [displayLoader2, setDisplayLoader2] = useState('none');
    const [errorMsg2, setErrorMsg2] = useState('');
    const [successMsg2, setSuccessMsg2] = useState('');

    const [fullName, setFullname] = useState('');
    const [email, setEmail] = useState('');
    const [mobile, setMobile] = useState('');
    const [gender, setGender] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [confirmNewPassword, setConfirmNewPassword] = useState('');
    const [signature, setSignature] = useState('');
    const [savedSignature, setSavedSignature] = useState('');

    const [showPassword1, setShowPassword1] = useState(false);
    const [hidePassword1, setHidePassword1] = useState(true);
    const [inputType1, setInputType1] = useState('password');
    const [showPassword2, setShowPassword2] = useState(false);
    const [hidePassword2, setHidePassword2] = useState(true);
    const [inputType2, setInputType2] = useState('password');

    const [selectedFile, setSelectedFile] = useState(null);
    const [imagePreview, setImagePreview] = useState(dpplaceholder);

    const [nameError, setNameError] = useState(false);
    const [emailError, setEmailError] = useState(false);
    const [mobileError, setMobileError] = useState(false);
    const [genderError, setGenderError] = useState(false);
    const [newPwdError, setNewPwdError] = useState(false);
    const [confirmNewPwdError, setConfirmNewPwdError] = useState(false);

    const fetchData = async (postObject) => {
        var qs = require('qs');
        try {
            const response = await axios.post(apiurl + 'user/get_user_details', qs.stringify(postObject));
            if (response && response.data.status === "success") {
                const responseData = response.data.data;
                setGender({ "label": responseData.gender, "value": responseData.gender });
                setFullname(responseData.name);
                setEmail(responseData.email);
                setMobile(responseData.mobile);
                setSavedSignature(responseData.signature);
            }
        } catch (err) {
            setErrorMsg(err);
        }
    }

    const handleFormSubmit = (e) => {
        e.preventDefault();
        setErrorFree();

        if (fullName === "") {
            setNameError(true);
            setErrorMsg('Enter full name');
            scrollToTop();
            return false;
        }

        if (gender === null || gender === false) {
            setGenderError(true);
            setErrorMsg('Select a gender');
            scrollToTop();
            return false;
        }

        let obj1 = {
            userid: localStorage.getItem('userid'),
            fullName: fullName,
            gender: gender
        };

        let obj2 = {};

        if (newPassword !== "") {
            if (confirmNewPassword !== "") {
                if (newPassword !== confirmNewPassword) {
                    setNewPwdError(true);
                    setConfirmNewPwdError(true);
                    setErrorMsg('Password mismatch');
                    scrollToTop();
                    return false;
                } else {
                    // Regex for validating password: 8 characters, 1 uppercase, 1 lowercase, 1 number, 1 special character
                    const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
                    if (!passwordRegex.test(newPassword)) {
                        setErrorMsg('Uh-oh! Your password needs at least 8 characters, one uppercase, one lowercase, one number, and one special character');
                        scrollToTop();
                        return false;
                    } else {
                        obj2 = {
                            newPassword: newPassword
                        };
                    }
                }
            } else {
                setConfirmNewPwdError(true);
                setErrorMsg('Enter confirm new password');
                scrollToTop();
                return false;
            }
        }

        const postObject = { ...obj1, ...obj2 };

        postData(postObject);
    }

    const postData = async (postObject) => {
        setDisplayLoader('inline-block');
        var qs = require('qs');
        try {
            const response = await axios.post(apiurl + 'user/update_user_details', qs.stringify(postObject));
            if (response && response.data.status === "success") {
                setErrorFree();
                setSuccessMsg(response.data.message);
                scrollToTop();

                const responseData = response.data.data;
                setGender({ "label": responseData.gender, "value": responseData.gender });
                setFullname(responseData.name);
            } else {
                setErrorFree();
                setErrorMsg(response.data.message);
            }
        } catch (err) {
            setErrorMsg(err);
        }
    }

    const setErrorFree = () => {
        setErrorMsg('');
        setSuccessMsg('');

        setErrorMsg2('');
        setSuccessMsg2('');

        setNameError(false);
        setEmailError(false);
        setMobileError(false);
        setGenderError(false);
        setNewPwdError(false);
        setConfirmNewPwdError(false);

        setDisplayLoader('none');
        setDisplayLoader2('none');
    }

    const scrollToTop = (element) => {
        if (element) {
            const ele = document.getElementById(element);
            window.scroll({
                top: ele.offsetTop - 50,
                behavior: 'smooth'
            })
        } else {
            window.scrollTo({ top: 0, behavior: 'smooth' });
        }
    }

    const showPwd = (num) => {
        if (num === 1) {
            setShowPassword1(true);
            setHidePassword1(false);
            setInputType1('text');
        }

        if (num === 2) {
            setShowPassword2(true);
            setHidePassword2(false);
            setInputType2('text');
        }
    }

    const hidePwd = (num) => {
        if (num === 1) {
            setShowPassword1(false);
            setHidePassword1(true);
            setInputType1('password');
        }

        if (num === 2) {
            setShowPassword2(false);
            setHidePassword2(true);
            setInputType2('password');
        }

    }

    // const handleFileChange = (e) => {
    //     const file = e.target.files[0];
    //     if (file) {
    //         setSelectedFile(file);
    //         // Preview the image
    //         const reader = new FileReader();
    //         reader.onloadend = () => {
    //             setImagePreview(reader.result);
    //         };
    //         reader.readAsDataURL(file);
    //     }
    // }

    // const handleUpload = async () => {
    //     if (!selectedFile) {
    //         setErrorMsg('Select a file first!');
    //         return;
    //     }

    //     const formData = new FormData();
    //     formData.append('image', selectedFile);

    //     try {
    //         const response = await axios.post(apiurl + 'fileuploads/update_profilepic', formData, {
    //             headers: {
    //                 'Content-Type': 'multipart/form-data',
    //             },
    //         });
    //         if (response && response.data.status === "success") {
    //             setSuccessMsg('Image uploaded successfully!');
    //         }
    //     } catch (error) {
    //         setErrorMsg('Error uploading image. Please try again.');
    //     }
    // }

    const signatureCanvas = useRef({});

    const clearSignature = (e) => {
        if(e){e.preventDefault();}
        signatureCanvas.current.clear();
        setSignature('');
    }

    const saveSignature = (e) => {
        e.preventDefault();
        setSignature(signatureCanvas.current.getTrimmedCanvas().toDataURL("image/png"));
    }

    const handleUploadSignature = async (e) => {
        e.preventDefault();
        setErrorFree();

        if (signature === "") {
            setErrorMsg2('Draw / Save your signature');
            scrollToTop("sign-section");
            return false;
        }

        const formData = new FormData();
        formData.append('signature', signature);
        formData.append('unique_id', localStorage.getItem('unique_id'));
        setDisplayLoader2('inline-block');
        try {
            const response = await axios.post(apiurl + 'fileuploads/upload_signature', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });
            if (response && response.data.status === "success") {
                setSuccessMsg2(response.data.message);
                scrollToTop("sign-section");
                setSavedSignature(response.data.signature);
                localStorage.setItem("signature", response.data.signature);
                setDisplayLoader2('none');
                clearSignature();
                setTimeout(() => {
                    setErrorFree();                    
                }, 5000);
            } else if (response && response.data.status === "failed") {
                setErrorMsg2(response.data.message);
                scrollToTop("sign-section");
            }
        } catch (error) {
            setErrorMsg2('Oops! Error updating signature. Please try again.');
            scrollToTop("sign-section");
        }
    }

    return (
        <div className="container-scroller">
            <Topbar />
            <div className="container-fluid page-body-wrapper panel-area">
                <Sidemenu />
                <div className="main-panel">
                    <div className="content-wrapper">
                        <div className="page-header">
                            <h3 className="page-title">
                                <span className="page-title-icon bg-gradient-primary text-white me-2">
                                    <RiSettings3Fill />
                                </span> Settings
                            </h3>
                        </div>
                        <div className="row">
                            <div className="col-12 grid-margin stretch-card">
                                <div className="card">
                                    <div className="card-body">
                                        <div className='err-display' style={{ display: (errorMsg) ? 'block' : 'none' }}> <FaExclamationTriangle /> <p>{errorMsg}</p></div>
                                        <div className='success-display' style={{ display: (successMsg) ? 'block' : 'none' }}> <FaCheck /> <p>{successMsg}</p></div>
                                        <form className="pt-3" onSubmit={handleFormSubmit} autoComplete="off">
                                            <p className="card-description"> Personal Details </p>
                                            <div className="row">
                                                <div className="col-md-6">
                                                    <div className="form-group row">
                                                        <label >Full Name &nbsp;<p style={{ display: "inline-block" }} className="note-text">(This will be displayed in the certificate)</p></label>
                                                        <div className="col-sm-12">
                                                            <input
                                                                className={nameError ? "form-control err-field" : "form-control"}
                                                                type="text"
                                                                onChange={(e) => setFullname(e.target.value)}
                                                                value={fullName}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="form-group row">
                                                        <label >Email</label>
                                                        <div className="col-sm-12">
                                                            <input
                                                                className={emailError ? "form-control err-field" : "form-control"}
                                                                type="email"
                                                                disabled
                                                                // onChange={(e) => setEmail(e.target.value)}
                                                                value={email}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-md-6">
                                                    <div className="form-group row">
                                                        <label >Mobile</label>
                                                        <div className="col-sm-12">
                                                            <input
                                                                className={mobileError ? "form-control err-field" : "form-control"}
                                                                type="number"
                                                                disabled
                                                                // onChange={(e) => setMobile(e.target.value)}
                                                                value={mobile}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="form-group row">
                                                        <label >Gender</label>
                                                        <div className="col-sm-12">
                                                            <Select
                                                                className={genderError ? "custom-select err-field" : "custom-select"}
                                                                options={Commonhelper.gender}
                                                                value={gender}
                                                                onChange={(e) => setGender(e)}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-md-6">
                                                    <div className="form-group row">
                                                        <label >New Password</label>
                                                        <div className="col-sm-12 pwd-div ">
                                                            <input
                                                                type={inputType1}
                                                                className={newPwdError ? "form-control form-control-lg err-field" : "form-control form-control-lg"} id="exampleInputPassword1"
                                                                placeholder="Password"
                                                                onChange={(e) => setNewPassword(e.target.value)}
                                                            />
                                                            {showPassword1 ? <FaEyeSlash className='pwd-show' onClick={(e) => hidePwd(1)} /> : <FaEye className='pwd-show' onClick={(e) => showPwd(1)} />}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="form-group row">
                                                        <label >Confirm New Password</label>
                                                        <div className="col-sm-12 pwd-div ">
                                                            <input
                                                                type={inputType2}
                                                                className={confirmNewPwdError ? "form-control form-control-lg err-field" : "form-control form-control-lg"} id="exampleInputPassword1"
                                                                placeholder="Password"
                                                                onChange={(e) => setConfirmNewPassword(e.target.value)}
                                                            />
                                                            {showPassword2 ? <FaEyeSlash className='pwd-show' onClick={(e) => hidePwd(2)} /> : <FaEye className='pwd-show' onClick={(e) => showPwd(2)} />}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="mt-3">
                                                <button className="btn btn-block btn-gradient-primary btn-lg font-weight-medium auth-form-btn" type="submit" disabled={(displayLoader == 'inline-block' ? 'disabled' : '')} >Update</button>
                                                <AiOutlineLoading className="loader" display={displayLoader} />
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row" id="sign-section">
                            <div className="col-12 grid-margin stretch-card">
                                <div className="card">
                                    <div className="card-body">
                                        <div className='err-display' style={{ display: (errorMsg2) ? 'block' : 'none' }}> <FaExclamationTriangle /> <p>{errorMsg2}</p></div>
                                        <div className='success-display' style={{ display: (successMsg2) ? 'block' : 'none' }}> <FaCheck /> <p>{successMsg2}</p></div>
                                        <form className="pt-3" onSubmit={handleUploadSignature} autoComplete="off">
                                            <p className="card-description"> Signature Upload </p>
                                            <div className="row">
                                                <div className="col-md-6">
                                                    <div className="form-group row">
                                                        <label >Sign Here</label>
                                                        <div className="col-sm-12">
                                                            <div className="signaturepad">
                                                                <SignaturePad
                                                                    ref={signatureCanvas}
                                                                    canvasProps={{
                                                                        className: "signatureCanvas"
                                                                    }}
                                                                />
                                                                <div className="sign-pad-buttons">
                                                                    <button className="saveSignature" onClick={(e) => saveSignature(e)}> {signature ? "Saved" : "Save"}</button>
                                                                    <button className="clearSignature" onClick={(e) => clearSignature(e)}>Clear</button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                {savedSignature &&
                                                    <div className="col-md-6">
                                                        <div className="form-group row">
                                                            <label >Signature</label>
                                                            <div className="col-sm-12">
                                                                <img src={mediaurl + "signatures/" + savedSignature} className="signature-img" />
                                                            </div>
                                                        </div>
                                                    </div>
                                                }
                                            </div>
                                            {/* <div className="row">
                                                <div className="col-md-6">
                                                    <div className="form-group row">
                                                        <label >Profile Image</label>
                                                        <div className="col-sm-12">
                                                            <img src={imagePreview} className="profile-img" /> <br />
                                                            <input type="file" onChange={handleFileChange} accept="image/*" />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div> */}
                                            <div className="mt-3">
                                                <button className="btn btn-block btn-gradient-primary btn-lg font-weight-medium auth-form-btn" type="submit" disabled={(displayLoader2 === 'inline-block' ? 'disabled' : '')} >Upload</button>
                                                <AiOutlineLoading className="loader" display={displayLoader2} />
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <Footer />
                </div>
            </div>
        </div>
    )
}

export default Settings