import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import { BrowserRouter as Router, Route, Routes} from 'react-router-dom';

import Login from "./Screens/Account/Login";
import Register from "./Screens/Account/Register";
import Nopage from './Screens/Nopage';
import Forgotpassword from './Screens/Account/Forgotpassword';
import Users from './Screens/Users/Users';
import Dashboard from './Screens/Dashboard';
import Certificates from './Screens/Certificates/Certificates';
import AddCertificate from './Screens/Certificates/AddCertificate';
import EditCertificate from './Screens/Certificates/EditCertificate';
import ViewCertificates from './Screens/Certificates/ViewCertificates';
import PDFview from './Screens/PDFview';
import Settings from './Screens/Account/Settings';
import Loft from './Screens/Loft';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  // <React.StrictMode>
  //   <App />
  // </React.StrictMode>

  <Router>
    <Routes>
      <Route exact path="/" element={<Login />} />
      <Route exact path="/login" element={<Login />} />
      <Route exact path="/register" element={< Register />} />
      <Route exact path="/forgotpassword" element={< Forgotpassword />} />
      <Route exact path="/dashboard" element={< Dashboard />} />
      <Route exact path="/certificates" element={< Certificates />} />
      <Route exact path="/certificates/add" element={< AddCertificate />} />
      <Route exact path="/certificates/edit/:id" element={< EditCertificate />} />
      <Route exact path="/viewcertificates" element={< ViewCertificates />} />
      <Route exact path="/viewpdf/:id" element={< PDFview />} />
      <Route exact path="/settings" element={< Settings />} />
      <Route exact path="/users" element={< Users />} />
      <Route exact path="/loft" element={< Loft />} />
      <Route exact path="*" element={< Nopage />} />
    </Routes>
  </Router>
);

