import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from "react-router-dom";
import Sidemenu from '../Layout/Sidemenu';
import Topbar from '../Layout/Topbar';
import Footer from '../Layout/Footer';
import { apiurl } from '../Config/Config';
import axios from 'axios';
import { GrCertificate } from "react-icons/gr";
import { Commonhelper } from '../Helpers/Commonhelper';
import { MdOutlineEdit, MdOutlineDeleteOutline } from "react-icons/md";
import { FaRegFilePdf } from "react-icons/fa6";
import { AiOutlineLoading } from "react-icons/ai";
import { FaAngleLeft } from "react-icons/fa6";
import { FaAngleRight, FaSortAlphaUp, FaSortAlphaDown } from 'react-icons/fa';
import { FiRefreshCcw } from "react-icons/fi";
import { CiSearch } from "react-icons/ci";



const Certificates = () => {

    const navigate = useNavigate();

    const [data, setData] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [recordsPerPage, setRecordsPerPage] = useState(Commonhelper.recordsPerPage);
    const [totalRecords, setTotalRecords] = useState(0);
    const [currentPageRecords, setCurrentPageRecords] = useState(0);
    const totalPages = Math.ceil(totalRecords / recordsPerPage);

    const [searchReportNumber, setSearchReportNumber] = useState('');
    const [searchFromDate, setSearchFromDate] = useState('');
    const [searchToDate, setSearchToDate] = useState('');

    const [sortBy, setSortBy] = useState('');
    const [sortField, setSortField] = useState('');

    const [displayLoader, setDisplayLoader] = useState('none');

    const navigatePage = (page) => {
        setCurrentPage(page);

        const postObj1 = {
            unique_id: localStorage.getItem('unique_id'),
            recordsPerPage: recordsPerPage,
            offset: (page - 1) * recordsPerPage
        };

        fetchData(postObj1);

    }

    function PaginationControls({ currentPage, setCurrentPage, totalPages }) {
        const pages = Array.from({ length: totalPages }, (_, i) => i + 1);

        return (
            <div className="page-btn-div">
                <span className="pagination-count">{(currentPage * recordsPerPage) - (recordsPerPage - 1)} - {currentPageRecords} of {totalRecords} Entries</span>
                <button
                    onClick={() => navigatePage(currentPage - 1)
                    }
                    disabled={currentPage === 1}
                >
                    <FaAngleLeft />
                </button>
                {
                    pages.map(page => (
                        <button
                            key={page}
                            onClick={() => navigatePage(page)}
                            disabled={currentPage === page}
                        >
                            {page}
                        </button>
                    ))}
                <button
                    onClick={() => navigatePage(currentPage + 1)}
                    disabled={currentPage === totalPages}
                >
                    <FaAngleRight />
                </button>
            </div>
        );
    }

    var postObj = {
        unique_id: localStorage.getItem('unique_id'),
        recordsPerPage: recordsPerPage,
        offset: (currentPage - 1) * recordsPerPage
    };

    useEffect(() => {
        if (Commonhelper.checkSession === false) {
            navigate("/login");
        } else {
            fetchData(postObj);
        }
    }, [navigate]);

    const fetchData = async (postObject) => {
        try {
            var qs = require('qs');
            const response = await axios.post(apiurl + 'certificates/list', qs.stringify(postObject));
            if (response && response.data.status === "success") {
                setData(response.data.data);
                setTotalRecords(response.data.total_records);
                setCurrentPageRecords(response.data.current_records)
            } else if (response && response.data.status === "no_records") {
                setData([]);
                // setErrorMsg(response.data.message);
            }
        } catch (err) {
            //   setErrorMsg(err);
        }
    };

    const deleteRow = async (id) => {
        if (id !== "") {
            let confirm = window.confirm("Are you sure to delete?");
            const postObject = { id: id };
            if (confirm) {
                var qs = require('qs');
                const response = await axios.post(apiurl + 'certificates/delete', qs.stringify(postObject));
                if (response && response.data.status === "success") {
                    fetchData(postObj);
                }
            }
        }
    }

    const changeStatus = async (id, currentstatus) => {
        if (id !== "") {
            let txt = (currentstatus === "A" ? "inactivate" : "activate");
            let confirm = window.confirm("Are you sure to " + txt + " the row?");
            const postObject = { id: id, currentstatus: currentstatus };
            if (confirm) {
                var qs = require('qs');
                const response = await axios.post(apiurl + 'certificates/change_status', qs.stringify(postObject));
                if (response && response.data.status === "success") {
                    fetchData(postObj);
                }
            }
        }
    }

    const handleSearch = async (e) => {
        e.preventDefault();

        const postObject = {
            unique_id: localStorage.getItem('unique_id'),
            recordsPerPage: recordsPerPage,
            offset: (currentPage - 1) * recordsPerPage,
            searchReportNumber: searchReportNumber,
            searchFromDate: searchFromDate,
            searchToDate: searchToDate
        };

        fetchData(postObject);
    }

    const handleClearSearch = (e, postObj) => {
        e.preventDefault();

        setSearchReportNumber('');
        setSearchFromDate('');
        setSearchToDate('');

        const postObject = {
            unique_id: localStorage.getItem('unique_id'),
            recordsPerPage: recordsPerPage,
            offset: (currentPage - 1) * recordsPerPage
        }

        fetchData(postObject);
    }

    const sortColumn = (sortBy, sortField) => {

        setSortBy(sortBy);
        setSortField(sortField);

        const postObject = {
            unique_id: localStorage.getItem('unique_id'),
            recordsPerPage: recordsPerPage,
            offset: (currentPage - 1) * recordsPerPage,
            sort_by: sortBy,
            sort_field: sortField
        };

        fetchData(postObject);
    }

    return (
        <div className="container-scroller">
            <Topbar />
            <div className="container-fluid page-body-wrapper panel-area">
                <Sidemenu />
                <div className="main-panel">
                    <div className="content-wrapper">
                        <div className="page-header">
                            <h3 className="page-title">
                                <span className="page-title-icon bg-gradient-primary text-white me-2">
                                    <GrCertificate />
                                </span> Certificates
                            </h3>
                            <nav aria-label="breadcrumb">
                                <ul className="breadcrumb">
                                    <Link to={"/certificates/add"}><button className='btn btn-gradient-primary brd-btn'>Add New</button></Link>
                                </ul>
                            </nav>
                        </div>
                        <div className="row">
                            <div className="col-lg-12 grid-margin stretch-card">
                                <div className="card">
                                    <div className="card-body">
                                        {/* <h4 className="card-title">Hoverable Table</h4>
                                        <p className="card-description"> Add class <code>.table-hover</code>
                                        </p> */}
                                        <div className="filter-div">
                                            <form className="pt-3" onSubmit={handleSearch} autoComplete="off">                                                
                                                <div className="form-group col-md-3">
                                                    <label>From Date</label>
                                                    <input
                                                        type="date"
                                                        className="form-control"
                                                        onChange={(e) => setSearchFromDate(e.target.value)}
                                                        value={searchFromDate}
                                                        min="2023-12-01"
                                                        max={Commonhelper.getCurrentDate}
                                                    />
                                                </div>
                                                <div className="form-group col-md-3">
                                                    <label>To Date</label>
                                                    <input
                                                        type="date"
                                                        className="form-control"
                                                        onChange={(e) => setSearchToDate(e.target.value)}
                                                        value={searchToDate}
                                                        min="2023-12-01"
                                                        max={Commonhelper.getCurrentDate}
                                                    />
                                                </div>
                                                <div className="form-group col-md-3">
                                                    <label>Report Number</label>
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        onChange={(e) => setSearchReportNumber(e.target.value)}
                                                        value={searchReportNumber}
                                                    />
                                                </div>
                                                <div className="col-md-3">
                                                    <button className="btn btn-inverse-primary btn-icon" onClick={(e) => handleClearSearch(e)}><FiRefreshCcw /></button> &nbsp;
                                                    <button className="btn btn-primary btn-icon" type="submit" disabled={(displayLoader == 'inline-block' ? 'disabled' : '')} ><CiSearch /></button>
                                                    <AiOutlineLoading className="loader" display={displayLoader} />
                                                </div>
                                            </form>
                                        </div>
                                        <table id="sortableTable" className="table table-hover list-table">
                                            <thead>
                                                <tr>
                                                    <th>Report Number</th>
                                                    <th className="mobile_col">Sticker Number</th>
                                                    <th className="mobile_col">Location</th>
                                                    <th className="mobile_col">Date of Examination {sortBy === "desc" ? <FaSortAlphaDown className="sort-icon" onClick={() => sortColumn("asc", "selected_date")}/> : <FaSortAlphaUp className="sort-icon" onClick={() => sortColumn("desc", "selected_date")}/> }</th>
                                                    {localStorage.getItem("user_type") === "admin" && <th className="mobile_col">Inspected By</th>}
                                                    {/* <th>Status</th> */}
                                                    <th>Actions</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {data.length > 0 ? data.map(item => (
                                                    <tr>
                                                        <td>{item.report_number}</td>
                                                        <td className="mobile_col">{item.sticker_number}</td>
                                                        <td className="mobile_col">{item.location}</td>
                                                        <td className="mobile_col">{item.selected_date}</td>
                                                        {localStorage.getItem("user_type") === "admin" && <td className="mobile_col">{item.inspector_name}</td>}
                                                        {/* <td className="text-danger"> 28.76% <i className="mdi mdi-arrow-down"></i></td> */}
                                                        {/* <td><label onClick={() => changeStatus(item.id, item.status)} className={item.status === "A" ? "badge badge-success" : "badge badge-danger"}>{item.status === "A" ? "Active" : "Inactive"}</label></td> */}
                                                        <td><Link to={"/certificates/edit/" + item.unique_id}><MdOutlineEdit className="table-action-icon" /></Link> {/*<GeneratePDF unique_id={item.unique_id} />*/} <a href={"/viewPDF/" + item.unique_id} target="_blank"><FaRegFilePdf className="table-action-icon" /></a> <MdOutlineDeleteOutline onClick={() => deleteRow(item.id)} className="table-action-icon" /></td>
                                                    </tr>
                                                )) : <tr className="table-loader-div">{/*<td colSpan={6}><AiOutlineLoading className="loader table-loader" /></td>*/}<td colSpan={6}>No Records Found</td></tr>}

                                                {/* <tr>
                                                    <td>Messsy</td>
                                                    <td>Flash</td>
                                                    <td className="text-danger"> 21.06% <i className="mdi mdi-arrow-down"></i></td>
                                                    <td><label className="badge badge-warning">In progress</label></td>
                                                </tr>
                                                <tr>
                                                    <td>John</td>
                                                    <td>Premier</td>
                                                    <td className="text-danger"> 35.00% <i className="mdi mdi-arrow-down"></i></td>
                                                    <td><label className="badge badge-info">Fixed</label></td>
                                                </tr>
                                                <tr>
                                                    <td>Peter</td>
                                                    <td>After effects</td>
                                                    <td className="text-success"> 82.00% <i className="mdi mdi-arrow-up"></i></td>
                                                    <td><label className="badge badge-success">Completed</label></td>
                                                </tr>
                                                <tr>
                                                    <td>Dave</td>
                                                    <td>53275535</td>
                                                    <td className="text-success"> 98.05% <i className="mdi mdi-arrow-up"></i></td>
                                                    <td><label className="badge badge-warning">In progress</label></td>
                                                </tr> */}
                                            </tbody>
                                        </table>
                                        <div className="pagination-div">
                                            < PaginationControls
                                                currentPage={currentPage}
                                                setCurrentPage={setCurrentPage}
                                                totalPages={totalPages}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <Footer />
                </div>
            </div>
        </div>
    )
}

export default Certificates