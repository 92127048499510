import React, { useState, useEffect } from 'react';
// import Bottommenu from './Layout/Bottommenu';
import axios from 'axios';
// import { apiurl } from '../Components/Config/Config';
import { FaCamera } from "react-icons/fa";

const Loft = () => {

  const [loftImage, setLoftImage] = useState(null);
  const [location, setLocation] = useState({ latitude: null, longitude: null });
  const [locationError, setLocationError] = useState(null);
  const [errorMsg, setErrorMsg] = useState('');
  const [successMsg, setSuccessMsg] = useState('');
  const [dataUrl, setDataUrl] = useState(null);

  useEffect(() => {
    checkLocationPermission();
  }, []);

  const checkLocationPermission = async () => {
    if (navigator.permissions) {
      try {
        const permissionStatus = await navigator.permissions.query({ name: 'geolocation' });
        if (permissionStatus.state === 'denied') {
          setLocationError("Location access is blocked. Please enable it in your browser settings.");
        }
      } catch (error) {
        console.error("Error checking location permission:", error);
      }
    }
  };

  // Function to handle image capture
  const handleImageCapture = (event) => {
    if (navigator.geolocation) {

      const options = {
        enableHighAccuracy: true, // Request the most accurate location
        timeout: 10000, // Timeout after 10 seconds
        maximumAge: 0, // No cached location
      };

      navigator.geolocation.getCurrentPosition(
        (position) => {
          setLocation({
            latitude: position.coords.latitude,
            longitude: position.coords.longitude,
            accuracy: position.coords.accuracy, // Accuracy in meters
          });
          setLocationError(null);
          const file = event.target.files[0];
          if (file) {
            const imageUrl = URL.createObjectURL(file);
            // const imageDataUrl = file.toDataURL("image/png");
            setLoftImage(imageUrl);
            postLoftData(file, position.coords.latitude, position.coords.longitude);
          }
        },
        (error) => {
          console.error("Error capturing location:", error);
          setLocationError("Location access denied. Please enable location services.");
        },
        options
      );
    } else {
      console.error("Geolocation is not supported by this browser.");
      setLocationError("Geolocation is not supported by this browser.");
    }

  };

  const postLoftData = async (imageUrl, lat, long) => {

    const formData = new FormData();
    formData.append('loftImage', imageUrl);
    formData.append('unique_id', localStorage.getItem('unique_id'));
    formData.append('latitude', lat);
    formData.append('longitude', long);
    try {
      // setDisplayLoader('inline-block');
      const response = await axios.post('https://thaifashionboutique.com/trial/pigeonpro/api/user/updateloft', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      if (response && response.data.status === "success") {
        setSuccessMsg(response.data.message);
      } else {
        setErrorMsg(response.data.message);
      }
    } catch (err) {
      setErrorMsg(err);
    }
  };

  return (
    <div className="container">
      <h4 className="page-title">My Loft</h4>

      {/* Camera input */}
      <div className="image_bg">
        <input
          type="file"
          accept="image/*"
          capture="environment"
          onChange={handleImageCapture}
          id="loft_image"
        />

        <label for="loft_image" className="loft_image_browse" > {loftImage ? <img src={loftImage} alt="Captured" style={{ width: "100%", height: "200px" }} /> : <FaCamera />}</label>
        {location.latitude && location.longitude && (
          <div className="lat_long_div">
            <p className="loft_lat_long">Latitude : {location.latitude}</p>
            <p className="loft_lat_long">Longitude : {location.longitude}</p>
            <p className="loft_lat_long">acc : {location.accuracy}</p>
          </div>
        )}
      </div>


      {locationError && <p style={{ color: 'red' }}>{locationError}</p>}

      {/* <Bottommenu /> */}
    </div>
  )
}

export default Loft