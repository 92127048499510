import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from "react-router-dom";
import Sidemenu from '../Layout/Sidemenu';
import Topbar from '../Layout/Topbar';
import Footer from '../Layout/Footer';
import { apiurl, mediaurl } from '../Config/Config';
import { Commonhelper } from '../Helpers/Commonhelper';
import axios from 'axios';
import { AiOutlineLoading } from "react-icons/ai";
import { FaAngleLeft } from "react-icons/fa6";
import { FaAngleRight, FaUsers } from 'react-icons/fa';

const Users = () => {

  const navigate = useNavigate();

  const [data, setData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [recordsPerPage, setRecordsPerPage] = useState(Commonhelper.recordsPerPage);
  const [totalRecords, setTotalRecords] = useState(0);
  const [currentPageRecords, setCurrentPageRecords] = useState(0);
  const totalPages = Math.ceil(totalRecords / recordsPerPage);

  const userData = async (postObject) => {
    try {
      var qs = require('qs');
      const response = await axios.post(apiurl + 'user/get_user_details', qs.stringify(postObject));
      if (response && response.data.status === "success") {
        const responseData = response.data.data;
        if (!responseData.user_type || responseData.user_type !== "admin") {
          navigate(-1);
        }
      } else {
        // setErrorFree();
        // setErrorMsg(response.data.message);
      }
    } catch (err) {
      //   setErrorMsg(err);
    }
  };

  const navigatePage = (page) => {

    setCurrentPage(page);

    const postObj1 = {
      recordsPerPage: recordsPerPage,
      offset: (page - 1) * recordsPerPage
    };

    fetchData(postObj1);

  }

  function PaginationControls({ currentPage, setCurrentPage, totalPages }) {
    const pages = Array.from({ length: totalPages }, (_, i) => i + 1);

    return (
      <div className="page-btn-div">
        <span className="pagination-count">{(currentPage * recordsPerPage) - (recordsPerPage - 1)} - {currentPageRecords} of {totalRecords} Entries</span>
        <button
          onClick={() => navigatePage(currentPage - 1)
          }
          disabled={currentPage === 1}
        >
          <FaAngleLeft />
        </button>
        {
          pages.map(page => (
            <button
              key={page}
              onClick={() => navigatePage(page)}
              disabled={currentPage === page}
            >
              {page}
            </button>
          ))}
        <button
          onClick={() => navigatePage(currentPage + 1)}
          disabled={currentPage === totalPages}
        >
          <FaAngleRight />
        </button>
      </div>
    );
  }

  var postObj = {
    unique_id: localStorage.getItem('unique_id'),
    recordsPerPage: recordsPerPage,
    offset: (currentPage - 1) * recordsPerPage
  };

  useEffect(() => {
    if (Commonhelper.checkSession === false) {
      navigate("/login");
    } else {
      const postObject = {
        userid: localStorage.getItem('userid')
      };

      userData(postObject);
      
      fetchData(postObj);

    }
  }, [navigate]);

  const fetchData = async (postObject) => {
    try {
      var qs = require('qs');
      const response = await axios.post(apiurl + 'user/list', qs.stringify(postObject));
      if (response && response.data.status === "success") {
        setData(response.data.data);
        setTotalRecords(response.data.total_records);
        setCurrentPageRecords(response.data.current_records)
      } else {
        // setErrorFree();
        // setErrorMsg(response.data.message);
      }
    } catch (err) {
      //   setErrorMsg(err);
    }
  };

  const changeStatus = async (id, currentstatus) => {
    if (id !== "") {
      let txt = (currentstatus === "A" ? "inactivate" : "activate");
      let confirm = window.confirm("Are you sure to " + txt + " the row?");
      const postObject = { id: id, currentstatus: currentstatus };
      if (confirm) {
        var qs = require('qs');
        const response = await axios.post(apiurl + 'user/change_status', qs.stringify(postObject));
        if (response && response.data.status === "success") {
          fetchData(postObj);
        }
      }
    }
  }

  return (
    <div className="container-scroller">
      <Topbar />
      <div className="container-fluid page-body-wrapper panel-area">
        <Sidemenu />
        <div className="main-panel">
          <div className="content-wrapper">
            <div className="page-header">
              <h3 className="page-title">
                <span className="page-title-icon bg-gradient-primary text-white me-2">
                  <FaUsers />
                </span> Users
              </h3>
              <nav aria-label="breadcrumb">
                <ul className="breadcrumb">
                  {/* <Link to={"/certificates/add"}><button className='btn btn-gradient-primary brd-btn'>Add New</button></Link> */}
                </ul>
              </nav>
            </div>
            <div className="row">
              <div className="col-lg-12 grid-margin stretch-card">
                <div className="card">
                  <div className="card-body">
                    <table className="table table-hover list-table">
                      <thead>
                        <tr>
                          <th>Name</th>
                          <th className="mobile_col">Mobile</th>
                          <th className="mobile_col">Email</th>
                          <th>Status</th>
                          {/* <th>Actions</th> */}
                        </tr>
                      </thead>
                      <tbody>
                        {data.length > 0 ? data.map(item => (
                          <tr>
                            <td>{item.name}</td>
                            <td className="mobile_col">{item.mobile}</td>
                            <td className="mobile_col">{item.email}</td>
                            {/* <td className="text-danger"> 28.76% <i className="mdi mdi-arrow-down"></i></td> */}
                            <td><label onClick={() => changeStatus(item.id, item.status)} className={item.status === "A" ? "badge badge-success" : item.status === "I" ? "badge badge-danger" : "badge badge-warning"}>{item.status === "A" ? "Active" : item.status === "I" ? "Inactive" : "Pending"}</label></td>
                            {/* <td><Link to={"/certificates/edit/" + item.unique_id}><MdOutlineEdit className="table-action-icon" /></Link> <GeneratePDF unique_id={item.unique_id} /> <a href={"/viewPDF/" + item.unique_id} target="_blank"><FaRegFilePdf className="table-action-icon" /></a> <MdOutlineDeleteOutline onClick={() => deleteRow(item.id)} className="table-action-icon" /></td> */}
                          </tr>
                        )) : <tr className="table-loader-div"><td colSpan={6}><AiOutlineLoading className="loader table-loader" /></td></tr>}
                      </tbody>
                    </table>
                    <div className="pagination-div">
                      < PaginationControls
                        currentPage={currentPage}
                        setCurrentPage={setCurrentPage}
                        totalPages={totalPages}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Footer />
        </div>
      </div>
    </div>
  )
}

export default Users