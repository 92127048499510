import React, { useState } from 'react';
import LogoRect from '../../Assets/images/logo-rectangle.png';
import LogoMini from '../../Assets/images/logo-mini.png';
import Dp from '../../Assets/images/dp-placeholder.jpg';
import { Link } from "react-router-dom";
import { RiMenu2Line, RiMenu3Line } from "react-icons/ri";

const Topbar = () => {

    const username = (localStorage.getItem('username') ? localStorage.getItem('username') : "User");

    const toggleSidebar = () => {
        const element = document.querySelector('.sidebar-offcanvas');
        if (element) {
            if (element.classList.contains('active')) {
                element.classList.remove('active');
            } else {
                element.classList.add('active');
            }
        }
    }

    const toggleBodyClass = () => {
        document.body.classList.toggle('sidebar-icon-only');
    };

    return (
        <div>
            <nav className="navbar default-layout-navbar col-lg-12 col-12 p-0 fixed-top d-flex flex-row">
                <div className="text-center navbar-brand-wrapper d-flex align-items-center justify-content-center">
                    <a className="navbar-brand brand-logo"><img src={LogoRect} alt="logo" /></a>
                    <a className="navbar-brand brand-logo-mini"><img src={LogoMini} alt="logo" /></a>
                </div>
                <div className="navbar-menu-wrapper d-flex align-items-stretch">
                    <button className="navbar-toggler navbar-toggler align-self-center" type="button" data-toggle="minimize">
                        <RiMenu2Line onClick={() => toggleBodyClass()} />
                    </button>
                    <ul className="navbar-nav navbar-nav-right">
                        <li className="nav-item nav-profile dropdown">
                            <a className="nav-link dropdown-toggle" id="profileDropdown" data-bs-toggle="dropdown" aria-expanded="false">
                                <div className="nav-profile-img">
                                    <img src={Dp} alt="image" />
                                    <span className="availability-status online"></span>
                                </div>
                                <div className="nav-profile-text">
                                    <p className="mb-1 text-black">{username} {/*<IoIosArrowDown />*/}</p>
                                </div>
                            </a>
                            {/* <div className="dropdown-menu navbar-dropdown" aria-labelledby="profileDropdown">
                                <a className="dropdown-item" href="#">
                                    <i className="mdi mdi-cached me-2 text-success"></i> Activity Log </a>
                                <div className="dropdown-divider"></div>
                                <a className="dropdown-item" href="#">
                                    <i className="mdi mdi-logout me-2 text-primary"></i> Signout </a>
                            </div> */}
                        </li>

                        {/* <li className="nav-item dropdown">
                            <a className="nav-link count-indicator dropdown-toggle" id="notificationDropdown" href="#" data-bs-toggle="dropdown">
                                <FaRegBell />
                                <span className="count-symbol bg-danger"></span>
                            </a>
                        </li> */}
                    </ul>
                    <button className="navbar-toggler navbar-toggler-right d-lg-none align-self-center" type="button" data-toggle="offcanvas">
                        <RiMenu3Line onClick={() => toggleSidebar()} />
                    </button>                    
                </div>
                {localStorage.getItem('signature') === "" &&  
                <div className="info-bar">Kindly update signature in <Link to={"/settings"}>settings</Link></div> }
            </nav>
        </div>
    )
}

export default Topbar