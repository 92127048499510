import React, { useState } from 'react';
import Logo from '../../Assets/images/logo-rectangle.png';
import { Link } from "react-router-dom";
import { apiurl } from '../Config/Config';
import axios from 'axios';
import { FaExclamationTriangle, FaCheck } from "react-icons/fa";
import { AiOutlineLoading } from "react-icons/ai";
import { FaRegFilePdf } from "react-icons/fa6";

const ViewCertificates = () => {

    const [data, setData] = useState('');
    const [errorMsg, setErrorMsg] = useState('');
    const [successMsg, setSuccessMsg] = useState('');
    const [reportNumber, setReportNumber] = useState('ESICO-LFT-');
    const [reportNumberError, setReportNumberError] = useState('');
    const [displayLoader, setDisplayLoader] = useState('none');

    const handleFormSubmit = (e) => {
        e.preventDefault();
        setErrorFree();

        if (reportNumber === null || reportNumber === false || reportNumber === "") {
            setReportNumberError(true);
            setErrorMsg('Enter the report number');
            scrollToTop();
            return false;
        }

        const postObject = {
            reportNumber: reportNumber
        };

        postData(postObject);
    }

    const postData = async (postObject) => {
        setDisplayLoader('inline-block');
        var qs = require('qs');
        try {
            const response = await axios.post(apiurl + 'certificates/list_for_verification', qs.stringify(postObject));
            if (response && response.data.status === "success") {
                setErrorFree();
                redirectPDF(response.data.data)
                // setData(response.data.data);
            } else {
                setErrorFree();
                setErrorMsg(response.data.message);
            }
        } catch (err) {
            setErrorMsg(err);
        }
    }

    const redirectPDF = (unique_id) => {
        window.open("/viewPDF/" + unique_id, "_blank");
    }

    const setErrorFree = () => {
        setErrorMsg('');
        setSuccessMsg('');
        setReportNumberError(false);
        setDisplayLoader('none');
    }

    const scrollToTop = () => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    };

    return (
        <div className="container-scroller">
            <div className="container-fluid page-body-wrapper full-page-wrapper">
                <div className="content-wrapper d-flex align-items-center auth">
                    <div className="row flex-grow">
                        <div className="col-lg-4 mx-auto">
                            <div className="auth-form-light text-left p-5">
                                <div className="brand-logo">
                                    <img src={Logo} />
                                </div>
                                <h4>Hey! Quickly Verify Your Certificates Here.</h4>
                                {/* <h6 className="font-weight-light">Report Number</h6> */}
                                <div className='err-display' style={{ display: (errorMsg) ? 'block' : 'none' }}> <FaExclamationTriangle /> <p>{errorMsg}</p></div>
                                <div className='success-display' style={{ display: (successMsg) ? 'block' : 'none' }}> <FaCheck /> <p>{successMsg}</p></div>
                                <form className="pt-3" onSubmit={handleFormSubmit} autoComplete="off">
                                    <div className="form-group">
                                        <input
                                            type="text"
                                            className={reportNumberError ? "form-control form-control-lg err-field" : "form-control form-control-lg"}
                                            placeholder="Report Number"
                                            onChange={(e) => setReportNumber(e.target.value)}
                                            value={reportNumber}
                                            style={{textTransform: "uppercase"}}
                                        />
                                    </div>
                                    <div className="mt-3">
                                        <button className="btn btn-block btn-gradient-primary btn-lg font-weight-medium auth-form-btn" type="submit" disabled={(displayLoader == 'inline-block' ? 'disabled' : '')} >Search</button>
                                        <AiOutlineLoading className='loader' display={displayLoader} />
                                    </div>
                                </form>
                                {/* <div className="verify-pdf-list">
                                    {data.length > 0 && data.map(item => (
                                        <div className="pdf-icon-par"><a className="pdf-icon-link" href={"/viewPDF/" + item.unique_id} target="_blank"><FaRegFilePdf className="pdf-icon" /><p className="pdf-icon-report-number">{item.report_number}</p></a></div>
                                    ))}
                                </div> */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ViewCertificates