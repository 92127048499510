import React from 'react'

const Footer = () => {
    return (
        <div>
            <footer className="footer">
                <div className="container-fluid d-flex justify-content-between">
                    <span className="text-muted d-block text-center text-sm-start d-sm-inline-block">Copyright © esico 2024</span>
                    {/* <span className="float-none float-sm-end mt-1 mt-sm-0 text-end"> Designed & Developed by Haja Najubudeen</span> */}
                </div>
            </footer>
        </div>
    )
}

export default Footer