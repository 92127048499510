import React, { useEffect, useState } from 'react';
import "../Pdfview.css";
import { apiurl, mediaurl } from './Config/Config';
import axios from 'axios';
import { useNavigate } from "react-router-dom";
import Logo from "../Assets/images/logo-rectangle.png";
import Logomini from "../Assets/images/logo-mini.png";
import Rejectedimg from "../Assets/images/rejected.png";
import viewQR from "../Assets/images/viewQR.png";
import QAQCSeal from "../Assets/images/QAQCSeal.png";
import inspectorseal from "../Assets/images/inspectorseal.png";
import { FaCircleCheck } from "react-icons/fa6";
import { IoIosCloseCircle } from "react-icons/io";
import { FaExclamationTriangle } from "react-icons/fa";

import salogo from "../Assets/pdflogos/Saudi-Aramco-logo.png";
import epmlogo from "../Assets/pdflogos/eastern-province-municipality-logo.png";
import iaflogo from "../Assets/pdflogos/iaf-logo.png";
import iaslogo from "../Assets/pdflogos/ias-logo.png";
import neomlogo from "../Assets/pdflogos/neom-logo.png";
import nwclogo from "../Assets/pdflogos/nwc-logo.png";
import rclogo from "../Assets/pdflogos/royal-commission-logo.png";
import sabiclogo from "../Assets/pdflogos/sabic-logo.png";
import seclogo from "../Assets/pdflogos/saudi-electric-company.png";
import leealogo from "../Assets/pdflogos/leea.png";

const PDFview = () => {

    const navigate = useNavigate();

    useEffect(() => {
        let pathname = window.location.pathname;
        let id_array = pathname.split('/');
        let unique_id = id_array[2];
        setuniqueId(unique_id);
        const postObject = {
            unique_id: unique_id
        };

        fetchData(postObject);
        
        printPage();

        const handleContextMenu = (e) => {
            e.preventDefault(); // Prevent the default right-click menu from appearing
        };
        // Add event listener to the document
        document.addEventListener('contextmenu', handleContextMenu);
        // Clean up the event listener when the component is unmounted
        return () => {
            document.removeEventListener('contextmenu', handleContextMenu);
        };
    }, []);

    const printPage = () => {
        setShowPrint(false);
        setTimeout(() => {
            window.print();
        }, 500);
        setTimeout(() => {
            setShowPrint(true);
            // navigate("/certificates");
        }, 2500);
    }

    // useEffect(() => {
    //     const handleContextMenu = (e) => {
    //         e.preventDefault(); // Prevent the default right-click menu from appearing
    //     };
    //     // Add event listener to the document
    //     document.addEventListener('contextmenu', handleContextMenu);
    //     // Clean up the event listener when the component is unmounted
    //     return () => {
    //         document.removeEventListener('contextmenu', handleContextMenu);
    //     };

    // }, []);

    const dateDisp = new Date();

    // Get year, month, and day with proper formatting
    const year = dateDisp.getFullYear();
    const month = String(dateDisp.getMonth() + 1).padStart(2, '0'); // Add leading zero if needed
    const day = String(dateDisp.getDate()).padStart(2, '0'); // Add leading zero if needed

    var formattedDate = `${day}-${month}-${year}`;

    const [errorMsg, setErrorMsg] = useState('');
    const [showPrint, setShowPrint] = useState(true);
    const [uniqueId, setuniqueId] = useState('');
    const [certificateTitle, setCertificateTitle] = useState(null);
    const [selectedDate, setSelectedDate] = useState(formattedDate);
    const [nextDate, setNextDate] = useState(formattedDate);
    const [appliedStandards, setAppliedStandards] = useState(null);
    const [reportNumber, setReportNumber] = useState('');
    const [stickerNumber, setStickerNumber] = useState('');
    const [employerNameAddress, setEmployerNameAddress] = useState('');
    const [location, setLocation] = useState('');
    const [equipmentNumber, setEquipmentNumber] = useState('');
    const [equipmentDescription, setEquipmentDescription] = useState('');
    const [safeWorking, setSafeWorking] = useState('');
    const [manufacturerName, setManufacturerName] = useState('');
    const [manufactureDate, setManufactureDate] = useState('');

    const [firstExamined, setFirstExamined] = useState('');
    const [installedCorrectly, setInstalledCorrectly] = useState('');
    const [monthsInterval, setMonthsInterval] = useState('');
    const [sixMonthsInterval, setSixMonthsInterval] = useState('');
    const [twelveMonthsInterval, setTwelveMonthsInterval] = useState('');
    const [examScheme, setExamScheme] = useState('');
    const [afterOccur, setAfterOccur] = useState('');
    const [defect, setDefect] = useState('');
    const [defect2, setDefect2] = useState('');
    const [iminentDanger, setIminentDanger] = useState('');
    const [repairRenewal, setRepairRenewal] = useState('');
    const [anyTests, setAnyTests] = useState('');
    const [observation, setObservation] = useState('');
    const [safeToOperate, setSafeToOperate] = useState('');
    const [inspectedBy, setInspectedBy] = useState('');
    const [signature, setSignature] = useState('');


    const fetchData = async (postObject) => {
        var qs = require('qs');
        try {
            const response = await axios.post(apiurl + 'certificates/get_details', qs.stringify(postObject));
            console.log(response.data, 'resssppp')
            if (response && response.data.status === "success") {
                const responseData = response.data.data;
                document.title = responseData.report_number;
                setCertificateTitle(responseData.certificate_title);
                setSelectedDate(responseData.sel_date);
                setNextDate(responseData.nex_date);
                setAppliedStandards(responseData.applied_standards);
                setReportNumber(responseData.report_number);
                setStickerNumber(responseData.sticker_number);
                setEmployerNameAddress(responseData.employer_name_address);
                setLocation(responseData.location);
                setEquipmentNumber(responseData.equipment_id);
                setEquipmentDescription(responseData.equipment_description);
                setSafeWorking(responseData.safe_working_loads);
                setManufacturerName(responseData.manufacturer_name);
                setManufactureDate(responseData.manufacture_date);
                setFirstExamined(responseData.first_examined);
                setInstalledCorrectly(responseData.installed_correctly);
                setMonthsInterval(responseData.months_interval);
                setSixMonthsInterval(responseData.six_months_interval);
                setTwelveMonthsInterval(responseData.twelve_months_interval);
                setExamScheme(responseData.exam_scheme);
                setAfterOccur(responseData.after_occur);
                setDefect(responseData.defect);
                setDefect2(responseData.defect2);
                setIminentDanger(responseData.iminent_danger);
                setRepairRenewal(responseData.repair_renewal);
                setAnyTests(responseData.any_tests_carried);
                setObservation(responseData.observation);
                setSafeToOperate(responseData.safe_to_operate);
                setInspectedBy(responseData.inspector_name);
                setSignature(responseData.signature);

            } else {
                navigate("/viewcertificates");
            }
        } catch (err) {
            setErrorMsg(err.message);
        }
    };

    return (
        <div>
            {errorMsg ? <div className="error-div"><p className="error-pdfview"><FaExclamationTriangle /> <br /> {errorMsg}</p></div> :
                <div className="pdf-container">
                    <div className="pdf-header">
                        <div className="pdf-header-logo">
                            <img src={Logo} />
                        </div>
                        <div className="pdf-header-address">
                            <p style={{ lineHeight: "24px" }} className="title arabtitle">شركة مساندة الإعمار للفحص</p>
                            <p style={{ lineHeight: "28px" }} className="title">Emaar Support Inspection Co.</p>
                            <p>6888, King Faisal Bin Abdul Aziz St, Ad Danah, Ras Tannurah, Saudi Arabia 32817</p>
                            <p>Tel : +966 507259023, +966 136670779, 055 8889186, 050 0484444</p>
                            <p>Email : info@esico.com.sa &nbsp; Website : www.esico.com.sa &nbsp; C.R : 2054100036 </p>
                        </div>
                    </div>
                    <div className="pdf-body">
                        <div className="pdf-title">
                            <p className="l1">{certificateTitle}</p>
                            {/* <p className="l2">{(certificateTitle === "CERTIFICATE OF THOROUGH EXAMINATION AND / OR TEST OF LIFTING APPLIANCE OR ACCESSORY") ? "This report complies with the requirements of the lifting operations and lifting equipment regulations 1998" : "This report complies with the requirements of the Kingdom of Saudi Arabia Regulations"}</p> */}
                        </div>
                        <div className="pdf-cert-details">
                            <table className="cd-table full">
                                <tr>
                                    <td colSpan={2} width="60%">Date of thorough examination : <b>{selectedDate ? selectedDate : "N/A"}</b></td>
                                    <td colSpan={3} width="40%">Report Number : <span style={{ color: "#37b420", fontWeight: "bold" }}>{reportNumber ? reportNumber : "N/A"}</span></td>
                                </tr>
                                <tr style={{ textAlign: "center" }}>
                                    <td colSpan={2} width="55%">Name & Address of employer</td>
                                    <td colSpan={1} width="15%">Location </td>
                                    <td colSpan={1} width="15%">Sticker No</td>
                                    <td colSpan={1} width="15%">Standards</td>
                                </tr>
                                <tr style={{ textAlign: "center", fontWeight: "bold" }}>
                                    <td colSpan={2} width="55%">{employerNameAddress ? employerNameAddress : "N/A"} </td>
                                    <td colSpan={1} width="15%">{location ? location : "N/A"}</td>
                                    <td colSpan={1} width="15%">{stickerNumber ? stickerNumber : "N/A"}</td>
                                    <td colSpan={1} width="15%">{appliedStandards ? appliedStandards : "N/A"}</td>
                                </tr>
                                <tr style={{ textAlign: "center" }}>
                                    <td width="22%">Equipment Identification No</td>
                                    <td width="33%">Equipment Description</td>
                                    <td width="15%">Safe Working Load(s)</td>
                                    <td width="15%">Date of Manufacture</td>
                                    <td width="15%">Manufacturer Name</td>
                                </tr>
                                <tr style={{ height: "150px", textAlign: "center", fontWeight: "bold" }}>
                                    <td>{equipmentNumber ? equipmentNumber : "N/A"}</td>
                                    <td style={{ textAlign: "left" }} dangerouslySetInnerHTML={{ __html: equipmentDescription ? equipmentDescription : "N/A" }}></td>
                                    {/* <td style={{ textAlign: "left" }}>{equipmentDescription ? equipmentDescription : "N/A"}</td> */}
                                    <td>{safeWorking ? safeWorking : "N/A"}</td>
                                    <td>{manufactureDate ? manufactureDate : "N/A"}</td>
                                    <td>{manufacturerName ? manufacturerName : "N/A"}</td>
                                </tr>
                                <tr>
                                    <td colSpan={2} style={{ padding: 0 }}>
                                        <tr>
                                            <td width="60%" style={{ border: 0, /*borderRight: "0.5px solid #ddd"*/ }}>Is this the first examination after installaton or assembly at a new site or location?</td>
                                            <td width="40%" style={{ border: 0, padding: "5px" }}>
                                                <tr>
                                                    <td width="10%">Yes</td>
                                                    <td width="10%">{firstExamined === "Yes" ? <FaCircleCheck className="yes_icon" /> : "-"}</td>
                                                    <td width="10%">No</td>
                                                    <td width="10%">{firstExamined === "No" ? <IoIosCloseCircle className="no_icon" /> : "-"}</td>
                                                </tr>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td width="60%" style={{ border: 0, /*borderRight: "0.5px solid #ddd"*/ }}>If the answer to the above question is YES, has the equipment been installed correctly?</td>
                                            <td width="40%" style={{ border: 0, padding: "5px" }}>
                                                <tr>
                                                    <td width="10%">Yes</td>
                                                    <td width="10%">{installedCorrectly === "Yes" ? <FaCircleCheck className="yes_icon" /> : "-"}</td>
                                                    <td width="10%">No</td>
                                                    <td width="10%">{installedCorrectly === "No" ? <IoIosCloseCircle className="no_icon" /> : "-"}</td>
                                                </tr>
                                            </td>
                                        </tr>
                                    </td>
                                    <td colSpan={3} style={{ padding: 0 }}>
                                        {/* <p style={{ padding: "10px" }}>Was the examination carried out </p> */}
                                        <tr>
                                            <td width="60%" style={{ border: 0, /*borderRight: "0.5px solid #ddd"*/ }}>Was the examination carried out within an interval of?</td>
                                            <td width="40%" style={{ border: 0 }}>- &nbsp; &nbsp; {monthsInterval ? monthsInterval + " Months" : "N/A"}</td>
                                        </tr>
                                        <tr>
                                            <td width="60%" style={{ border: 0, /*borderRight: "0.5px solid #ddd"*/ }}>In accordance with an examination scheme?</td>
                                            <td width="40%" style={{ border: 0, padding: "5px" }}>
                                                <tr>
                                                    <td width="10%">Yes</td>
                                                    <td width="10%">{examScheme === "Yes" ? <FaCircleCheck className="yes_icon" /> : "-"}</td>
                                                    <td width="10%">No</td>
                                                    <td width="10%">{examScheme === "No" ? <IoIosCloseCircle className="no_icon" /> : "-"}</td>
                                                </tr>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td width="60%" style={{ border: 0, /*borderRight: "0.5px solid #ddd"*/ }}>After the occurence of exceptional circumstances?</td>
                                            <td width="40%" style={{ border: 0, padding: "5px" }}>
                                                <tr>
                                                    <td width="10%">Yes</td>
                                                    <td width="10%">{afterOccur === "Yes" ? <FaCircleCheck className="yes_icon" /> : "-"}</td>
                                                    <td width="10%">No</td>
                                                    <td width="10%">{afterOccur === "No" ? <IoIosCloseCircle className="no_icon" /> : "-"}</td>
                                                </tr>
                                            </td>
                                        </tr>
                                    </td>
                                </tr>
                                <tr>
                                    <td colSpan={5}>Identification of any part found to have a defect which is or could become a danger to persons and a description of the defect (if none state NONE) : {defect ? defect : "N/A"}</td>
                                </tr>
                                <tr>
                                    <td width="80%" colSpan={4}>Is the above an existing or imminent danger to persons, Note: This is a reportable defect?</td>
                                    <td width="20%">{iminentDanger ? iminentDanger : "N/A"}</td>
                                </tr>
                                <tr>
                                    <td colSpan={3}>Is the above a defect which is not yet could become a danger to persons (if YES state the date by when)</td>
                                    <td colSpan={2}>Yes by : {defect2 ? defect2 : "N/A"}</td>
                                </tr>
                                <tr>
                                    <td colSpan={5}>Particulars of any repair, renewal or alteration required to remedy the defect identified above : {repairRenewal ? repairRenewal : "N/A"}</td>
                                </tr>
                                <tr>
                                    <td colSpan={5}>Particulars of any tests carried out as part of the examination (if none state NONE) : {anyTests ? anyTests : "N/A"}</td>
                                </tr>
                                <tr style={{ fontWeight: "bold" }}>
                                    <td colSpan={5}>Observation/additional comments relative to this thorough examination : {observation ? observation : "N/A"}</td>
                                </tr>
                            </table>
                            <div className="con-div">
                                <div className="con-img-div">
                                    <img src={viewQR} className="view-qr" style={{ marginRight: "5px" }} />
                                    <img src={QAQCSeal} className="view-qr" />
                                    <p className="qr-info">For confirmation scan QR code<br />search with report number : <b>{reportNumber}</b></p>
                                </div>
                                <div className="safe-div">
                                    {safeToOperate === "Yes" ? <p className="safe-div-yes"><FaCircleCheck className="sd-icon" /> &nbsp; Safe to operate</p> : ""}
                                    {safeToOperate === "No" ? <p className="safe-div-no"><IoIosCloseCircle className="sd-icon" /> Unsafe to operate</p> : ""}
                                    {safeToOperate === "Yes" && nextDate ? <p className="next-date">Next Inspection : {nextDate}</p> : ""}
                                </div>
                                <div className="con-result-div">
                                    <p className="ins-by">Inspected by : {inspectedBy}</p>
                                    <img src={inspectorseal} className="sign-img" style={{ marginRight: "-50px" }} />
                                    <img src={mediaurl + "signatures/" + signature} className="sign-img" />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="pdf-footer">
                        <div className="footer-logo-div">
                            <div className="footer-logo"><img src={leealogo} style={{maxHeight: "40px"}} /></div>
                            <div className="footer-logo"><img src={salogo} /><p className="footer-logo-vendor-text">10056897</p></div>
                            <div className="footer-logo"><img src={epmlogo} /><p className="footer-logo-vendor-text">EP-Q4634</p></div>
                            <div className="footer-logo"><img src={neomlogo} /><p className="footer-logo-vendor-text">1100004098</p></div>
                            <div className="footer-logo"><img src={nwclogo} /><p className="footer-logo-vendor-text">33564</p></div>
                            <div className="footer-logo"><img src={rclogo} /><p className="footer-logo-vendor-text">10739</p></div>
                            <div className="footer-logo"><img src={sabiclogo} /><p className="footer-logo-vendor-text">001052261</p></div>
                            <div className="footer-logo"><img src={seclogo} /><p className="footer-logo-vendor-text">05022531</p></div>
                            <div className="footer-logo"><img src={iaflogo} /></div>
                            <div className="footer-logo"><img src={iaslogo} /></div>
                        </div>
                        {showPrint ? <div style={{ textAlign: "center" }}><button className="print-btn" onClick={printPage}>Print</button></div> : ""}
                    </div>
                    <div className="watermark-div">
                        <img src={Logomini} style={{ width: "400px", opacity: "0.03" }} />
                    </div>
                    {safeToOperate === "No" ? <div className="rejected-div">
                        <img src={Rejectedimg} style={{ width: "300px" }} />
                    </div> : ""}
                </div>
            }
        </div>
    )
}

export default PDFview